import React, { useState } from 'react';

function PopupUploadMusic({ isOpen, onClose, onCloseDelay, artist }) {
  const [dragging, setDragging] = useState(false);
  const [files, setFiles] = useState([]);
  const [name, setName] = useState('');
  const [userId, setUserId] = useState('2');
  const [uploading, setUploading] = useState(false);


  const handleDragEnter = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);

    const droppedFiles = Array.from(e.dataTransfer.files);
    setFiles(droppedFiles);
    setName(TrackNameExtractor(droppedFiles[0].name, artist.artistname));
  };

  const uploadFileAndSave = () => {
    if (files.length === 0) {
      // No files to upload
      return;
    }

    setUploading(true);

    // Create a FormData object to send files and additional data (e.g., name)
    const formData = new FormData();
    formData.append('name', name); // Add additional data
    formData.append('userId', userId); // Add additional data
    formData.append('artistId', artist.artistid); // Add additional data



    files.forEach((file, index) => {
      formData.append(`file-${index}`, file); // Add each file to the form data
    });

    // Make a POST request to your server
    fetch('/api/upload', {
      method: 'POST',
      body: formData,
      timeout: 600000, // 10 minutes timeout in milliseconds
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the server's response (if needed)
        console.log('Server response:', data);
        //onCloseDelay();
      })
      .catch((error) => {
        // Handle any errors
        console.error('Error:', error);
      });
      onCloseDelay();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="bg-white p-4 pt-10 uploadpopup shadow-lg relative">
        {/* Dialog content */}
        <div className="cursor-pointer popupclose text-2xl text-gray-500 absolute right-3 top-3" onClick={onClose}>
          x
        </div>
        <div
          className={`relative flex cursor-pointer uploadarea rounded-lg border-2 border-gray-400 bg-gray-100 text-gray-400 font-gray-400 text-6xl items-center justify-center ${dragging ? 'border-dashed border-gray-500' : ''}`}
          onDragEnter={handleDragEnter}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          {files.length > 0 ? (
            <ul className="popupfilelist">
              {files.map((file, index) => (
                <li key={index}>{file.name}</li>
              ))}
            </ul>
          ) : (
            <>+</>
          )}
        </div>
        <div className="pt-2 font-bold text-gray-700">
          Name
          <br />
          {/* Input field */}
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="w-full border-2 border-gray-500 rounded-lg py-2 px-4"
            placeholder="e.g. Born Slippy"
          />
        </div>
        <div className="py-2 flex justify-center">
          {/* Center the button horizontally */}
          { uploading ? (
            <button className="animate-pulse bg-gray-600 rounded-full font-bold text-white px-4 py-2 rounded mt-4">uploading & analyzing</button>
          ) : (
          <button className="bg-gray-900 hover:bg-gray-800 rounded-full font-bold text-white px-4 py-2 rounded mt-4" onClick={uploadFileAndSave}>
            Make me a star!
          </button>
         )}
        </div>
      </div>
    </div>
  );

  function TrackNameExtractor( fileName, artistName ) {

    console.info("FILE NAME", fileName);
    console.info("ARTIST NAME", artistName);

    // Define a regular expression pattern to remove artist name, common punctuation, and underscores
    const cleanedFileName = fileName
    .replace('-', ' ')
    .replace('-', ' ')
    .replace(artistName, '') // Remove the artist name
    .replace(/\s+/g, ' ') // multiple whitespaces into one
    .replace('.wav', '') // .wav
    .replace('.mp3', '') // .wav
    .replace('-', ' ')
    //.replace(/[-,:_]/g, '') // Remove hyphens, commas, and colons
    .replace(/[,:_]/g, '') // Remove commas, and colons
    .trim(); // Trim any leading or trailing spaces



    return cleanedFileName;
  }
}

export default PopupUploadMusic;
