import React, { useState } from 'react';

function SearchResultList({ results, artistId, userId }) {
  const [selectedResults, setSelectedResults] = useState([]);

  const handleCircleClick = (result) => {
    if (selectedResults.includes(result)) {
      setSelectedResults(selectedResults.filter((selectedResult) => selectedResult !== result));
    } else {
      setSelectedResults([...selectedResults, result]);
    }
  };

  const isResultSelected = (result) => selectedResults.includes(result);

  const handleCheckboxChange = (event, result) => {
    if (event.target.checked) {
      setSelectedResults([...selectedResults, result]);
    } else {
      const updatedResults = selectedResults.filter(
        (selectedResult) => selectedResult.link !== result.link
      );
      setSelectedResults(updatedResults);
    }
  };

  const handleSaveClick = () => {
    // Include artistId and userId in the data to be sent
    const dataToSave = selectedResults.map((result) => ({
      ...result,
      artistId,
      userId,
    }));

    //console.info("DATA", dataToSave);



    // Send a request to your server to save dataToSave to tblLink
    fetch('/api/saveLinks', {
      method: 'POST',
      headers: {
        'content-type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(dataToSave),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the response from the server
        console.log('Saved successfully:', data);
      })
      .catch((error) => {
        console.error('Error saving data:', error);
      });
  };

  return (
    <div id="searchresults">
      <ul>




        {results.map((result, index) => (
          <li key={index}>
            <div
              className={`circle ${isResultSelected(result) ? 'selected' : ''}`}
              onClick={() => handleCircleClick(result)}
            >
            {isResultSelected(result) && <span className="checkmark">&#x2713;</span>}
          </div>
            <span className="bioSearchTitle"><a href={result.link} target="_blank" rel="noopener noreferrer">{result.title}</a></span>
            <p>{result.snippet}</p>
            <p><span className="bioStrong">Display Link:</span> {result.displayLink}</p>
            {result.ogType && <p><span className="bioStrong">OG Type:</span> {result.ogType}</p>}
            {result.ogTitle && <p><span className="bioStrong">OG Title:</span> {result.ogTitle}</p>}
            {result.ogDescription && <p><span className="bioStrong">OG Description:</span> {result.ogDescription}</p>}
            <br/>
          </li>
        ))}
      </ul>
      <button className="saveLinks" onClick={handleSaveClick}>Save Selected</button>
    </div>
  );
}

export default SearchResultList;
