import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import axios from 'axios';

import Artists from './Artists';
import SearchResultList from './SearchResultList';



function Bio(props) {

  const artists = props.artists;
  const selectedArtist = props.selectedArtist;
  const handleArtistSelection = props.handleArtistSelection;


  const [searchResult, setSearchResult] = useState(null); // Initialize user as null


  useEffect(() => {
      // Define a function to fetch search results
      const fetchSearchResults = async () => {
        if (selectedArtist) {
          try {
            // Make an API request to your backend to perform the search
            const response = await axios.get(`/api/search?q=artist musician ${selectedArtist.artistname}`);
            setSearchResult(response.data); // Update state with search results
          } catch (error) {
            console.error(error);
            setSearchResult(null); // Handle errors here, e.g., set searchResult to null
          }
        }
      };

      // Call the fetchSearchResults function when the selectedArtist changes
      fetchSearchResults();
    }, [selectedArtist]);









  return (
    <div className="flex h-screen">
      <Artists
      artists={artists}
      selectedArtist={selectedArtist}
      handleArtistSelection={handleArtistSelection}/>

      <div className="flex-1 min-h-full w-4/5 homemain">
        {selectedArtist && (
        <nav className="homenav">
          <div className="mx-auto">
            <div className="flex items-center justify-between">
              <ul className="flex space-x-2">
                <li><Link className="hover:text-black" to="/">Releases</Link></li>
                <li><Link className="hover:text-black active" to="/Bio">Bio</Link></li>
                <li><Link className="hover:text-black" to="/Assets">Assets</Link></li>
              </ul>
            </div>
          </div>
        </nav>
        )}
        <div className="p-4 flex overflow-auto" id="playarea">
        <div id="content" className="mt-2">
        {selectedArtist ? (
          <div>
            {searchResult ? (
              <div className="ml-10">
              <h2 className="bioArtistName">{selectedArtist.artistname}, is this you?</h2>
                <br/>
                <SearchResultList
                results = {searchResult}
                userId = {selectedArtist.artist_userid}
                artistId = {selectedArtist.artistid}
                />
                <br/><br/>


              </div>
            ) : (
              <div className="ml-10">Loading search results...</div>
            )}
          </div>
        ) : (
          <div>&#xa0;</div>
        )}
      </div> {/* #content */}
        </div> {/* #playarea */}
      </div>
    </div>
  );
}

export default Bio;
