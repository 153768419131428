import React, { useState, useEffect, useRef } from 'react';
import { useParams, NavLink, Link  } from 'react-router-dom';

import Tags from './Tags'; // Import the Tags component
import Artists from './Artists'; // Import the Tags component
import Sliders from './Sliders';

const albumCoverImages = "/images"


function Release(props) {
  const { releaseId } = useParams(); // Get the releaseid from the route parameters
  const [release, setRelease] = useState([]);
  const [sliderValue1, setSliderValue1] = useState(null); // default to start
  const [regenerating, setRegenerating] = useState(false);

  const [dragging, setDragging] = useState(false);
  const [files, setFiles] = useState([]);
  const [userId, setUserId] = useState('2');
  const [uploading, setUploading] = useState(false);


  const artists = props.artists;
  const selectedArtist = props.selectedArtist;
  const handleArtistSelection = props.handleArtistSelection;
  const artistReleases = props.artistReleases;
  const setArtistReleases = props.setArtistReleases;

  const apiCost = props.apiCost;
  const setApiCost = props.setApiCost;

  const saveTimeoutRef = useRef(null);

  function getApiCost() {
    fetch('/api/apicost')
      .then((response) => response.json()) // Parse response as JSON
      .then((data) => {
        setApiCost(data); // Access the user property
      })
      .catch((error) => {
        console.error(error);
        setApiCost('Error fetching user data');
      });
  }


  useEffect(() => {
    // Fetch release data when the component mounts
    getRelease(releaseId);
  }, []);

  function handlePressreleaseRegenerate() {
  setRegenerating(true); // Start the regeneration process

  fetch(`/api/release/pressrelease/${releaseId}`, {
      method: 'PUT',
      headers: {
          'Content-Type': 'application/json',
      }
  })
  .then((response) => {
      if (!response.ok) {
          throw new Error('Network response was not ok');
      }
      return response.json();
  })
  .then((data) => {
      console.log('Regeneration successful:', data);
      getRelease(releaseId);
      getApiCost();
  })
  .catch((error) => {
      console.error('Error regenerating press release:', error);
  })
  .finally(() => {
      setRegenerating(false); // End the regeneration process
  });
}


  function handleSliderChange(sliderValue) {
    // Clear the existing timer (if it exists)
    if (saveTimeoutRef.current) {
        clearTimeout(saveTimeoutRef.current);
    }

    //setSliderValue1(sliderValue);

    // Set a new timer
    saveTimeoutRef.current = setTimeout(() => {
        saveSliderToDb(sliderValue);
    }, 1000); // 1000 ms = 1 second
  }

  function saveSliderToDb(value) {

    // Making a PUT request to save the updated slider value
    fetch(`/api/release/${releaseId}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ releaseslidercreative: value }),
    })
    .then((response) => {
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return response.json();
    })
    .then((data) => {
        console.log('Save successful:', data);
    })
    .catch((error) => {
        console.error('Error saving slider value:', error);
    });
  }

  function getRelease(releaseId) {
    // Fetch release by the selected releaseId
    fetch(`/api/release/${releaseId}`)
      .then((response) => response.json())
      .then((data) => {
        setRelease(data); // Update the release state with the fetched data
        setSliderValue1(data[0].releaseslidercreative);
      })
      .catch((error) => {
        console.error('Error fetching release:', error);
        setRelease(null); // Set release to null in case of an error
      });

  }


  const handleDragEnter = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = (e) => {

    e.preventDefault();
    setDragging(false);

    const droppedFiles = Array.from(e.dataTransfer.files);

    //setFiles(droppedFiles);
    uploadFileAndSave(droppedFiles);
  };

  /* FILE UPLOAD FUNCTIONS */

  const uploadFileAndSave = (droppedFiles) => {
    if (droppedFiles.length === 0) {
      // No files to upload
      return;
    }

    setUploading(true);

    // Create a FormData object to send files and additional data (e.g., name)
    const formData = new FormData();
    //formData.append('name', name); // Add additional data
    formData.append('userId', userId); // Add additional data
    formData.append('artistId', release[0].release_artistid); // Add additional data
    formData.append('releaseId', releaseId); // Add additional data



    droppedFiles.forEach((file, index) => {
      formData.append(`file-${index}`, file); // Add each file to the form data
    });

    // Make a POST request to your server
    fetch('/api/uploadcover', {
      method: 'POST',
      body: formData,
      timeout: 600000, // 10 minutes timeout in milliseconds
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the server's response (if needed)
        console.log('Server response:', data);
        getRelease(releaseId);

      })
      .catch((error) => {
        // Handle any errors
        console.error('Error:', error);
      });
  };


  return (

    <div className="flex h-screen graymain">
      <Artists
      artists={artists}
      selectedArtist={selectedArtist}
      handleArtistSelection={handleArtistSelection}/>



        <div className="flex w-3/5 overflow-auto" id="playarea">
          <div id="content">
          {release.length === 0 ? (
            <p>Waiting for your press release to generate</p>
          ) : regenerating ? (
            <p>Regenerating Press Release...</p>
          ) : (
            <div>
              <div className="float-left">
                <div className="breadcrumb">
                  <span className="bcparent">Releases</span> / {release[0].releasename}
                </div>
                <div className="releasetitle">
                  {release[0].releasename}
                </div>
                <nav className="releasenav">
                  <div className="mx-auto">
                    <div className="flex items-center justify-between">
                      <ul className="flex space-x-2">
                        <li><Link className="hover:text-black active" to={`/Release/${release[0].releaseid}`}>Press Release</Link></li>
                        <li><Link className="hover:text-black" to={`/Lyrics/${release[0].releaseid}`}>Lyrics</Link></li>
                        <li><Link className="hover:text-black" to={`/SocialContent/${release[0].releaseid}`}>Social Content</Link></li>
                        <li><Link className="hover:text-black" to={`/ReleasePlan/${release[0].releaseid}`}>Release Plan</Link></li>

                      </ul>
                    </div>
                  </div>
                </nav>
              </div>
              <div className="float-right">


              <div
                className={`releasealbumthumb border-gray-900 ${dragging ? 'border-4 border-dashed' : ''} ${!release[0].releasealbumcover ? 'border-4' : ''} `}
                onDragEnter={handleDragEnter}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
                style={{
                  backgroundImage: release[0].releasealbumcover
                    ? `url(${albumCoverImages}/${userId}/${release[0].releasealbumcover})`
                    : 'none',
                  backgroundSize: 'cover', // Set background size to 'cover'
                }}
                >
                {!release[0].releasealbumcover && <span>&#8679;</span>}
              </div>
              </div>
              <br clear="all"/>
              <div className="releaseasset">
                {release[0].releasepressrelease ? (
                  <p dangerouslySetInnerHTML={{ __html: release[0].releasepressrelease }} />
                ) : (
                  <p className="animate-pulse">Generating Press Release</p>
                )}
              </div>
            </div>
          )}


          </div> {/* #content */}
        </div> {/* #playarea */}

        <div id="analysis" className="w-1/5 overflow-auto">
          <div className="analysistitle">
            Tone of Voice
          </div>

          <Sliders sliderValue1={sliderValue1} setSliderValue1={setSliderValue1} handleSliderChange={handleSliderChange}/>
          {/* Call the Tags component and pass the release data as a prop*/}
          <Tags releaseAnalysis={release} analysisType="releasemusicanalysis" />
          <Tags releaseAnalysis={release} analysisType="releaselyricsanalysis" />


          <button id="analysisSave" onClick={handlePressreleaseRegenerate}>Regenerate</button>


        </div>


    </div>
  );
}

export default Release;
