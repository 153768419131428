function Tags({ releaseAnalysis, analysisType }) {



  if (releaseAnalysis && releaseAnalysis.length > 0) {
  // Access the releaselyricsanalysis field from the first (and only) object in the array
  let jsonData;
  let loading = "";

  if (analysisType === 'releaselyricsanalysis') {
    if(releaseAnalysis[0].releaselyricsanalysis !== null && releaseAnalysis[0].releaselyricsanalysis.length > 0) {
      jsonData = JSON.parse(releaseAnalysis[0].releaselyricsanalysis);
    } else {
      jsonData = {};
      loading = "Creating lyrics analysis";
    }
  } else if (analysisType === 'releasemusicanalysis') {
    if(releaseAnalysis[0].releasemusicanalysis !== null && releaseAnalysis[0].releasemusicanalysis.length > 0) {
      jsonData = JSON.parse(releaseAnalysis[0].releasemusicanalysis);
    } else {
      jsonData = {};
      loading += "\n\nCreating music analysis";

    }
  } else {
    // Handle invalid analysisType or provide a default behavior
    jsonData = {};

  }


  const tagElements = [];

for (const key in jsonData) {
  if (Array.isArray(jsonData[key])) {
    // Handle the case of arrays
    tagElements.push(
      <div key={key}>
        <div className="analysistitle">{key}</div>
        <ul>
          {jsonData[key].map((item, index) => (
            <li key={index}>
              {typeof item === 'object'
                ? Object.entries(item).map(([subKey, subValue]) => (
                    <div key={subKey}>
                      {subKey}: {subValue}
                    </div>
                  ))
                : item}
            </li>
          ))}
        </ul><br clear="all"/>
      </div>
    );
  } else if (typeof jsonData[key] === 'object') {
    // Handle the case of nested objects
    tagElements.push(
      <div key={key}>
        <div className="analysistitle">{key}</div>
        <ul>
          {Object.entries(jsonData[key]).map(([subKey, subValue]) => (
            <li key={subKey}>
              {subKey}: {subValue}
            </li>
          ))}
        </ul><br clear="all"/>
      </div>
    );
  } else {
    // Handle other data types
    tagElements.push(
      <div key={key}>
        <div className="analysistitle">{key}</div>
        <ul>
          <li className={key}>{jsonData[key]}</li>
        </ul><br clear="all"/>
      </div>
    );
  }
}

  return (
    <div className={`tags ${analysisType}`}>
      {tagElements}
      {loading && (
        <div className="text-center animate-pulse">
        <br/><br/>
        {loading}
        </div>
      )}
    </div>
  );

} else {
  return (
    <div>
      Loading
    </div>
  );
}

}
export default Tags;
