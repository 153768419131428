import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function Artists(props) {

  const { artists, selectedArtist, handleArtistSelection, getArtists } = props;
  const userId = 2;

  // New state variables for managing a new artist
  const [addingArtist, setAddingArtist] = useState(false);
  const [newArtistName, setNewArtistName] = useState('');

  // Handler for adding a new artist
  const handleAddArtist = () => {
    setAddingArtist(true);
  };

  // Handler for the new artist name input change
  const handleNewArtistNameChange = (e) => {
    setNewArtistName(e.target.value);
  };

  const handleSaveNewArtist = async () => {
    try {
      const response = await fetch('/api/addArtist', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          artistName: newArtistName,
          userId: 2, // Assuming userId is always 2
        }),
      });

      if (response.ok) {
        // The artist was successfully added to the database
        console.log('Artist added successfully');
        setAddingArtist(false);
        setNewArtistName('');
        getArtists();
      } else {
        // Handle error cases, e.g., display an error message to the user
        console.error('Error adding artist');
      }
    } catch (error) {
      console.error('Error adding artist:', error);
    }
  };

  // Handler for the Enter key press
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSaveNewArtist();
    }
  };

return (
  <div id="artists" className="w-1/5">
  <div id="artistHeader">
      <div id="addArtist" onClick={handleAddArtist}>+</div>
      <div id="artists-title">Artists</div>
    </div>



    <div id="artistlist" className="mt-2">

    {addingArtist && (
        <div className="artist">
          <div className="artistContent">
            <div className="artistImage bg-gradient-to-r from-red-400 via-gray-300 to-blue-500">&#xa0;</div>
            <div className="artistNameAndLevel">
              <div className="artistNameWithInput">
                <input className="artistInput"
                  type="text"
                  value={newArtistName}
                  onChange={handleNewArtistNameChange}
                  onKeyPress={handleKeyPress}
                  placeholder="New artist name"
                  autoFocus
                />
              </div>
              <div className="artistLevel">NOOB</div>
            </div>
          </div>

          {/* Optionally add a save button next to the input field */}
          <button onClick={handleSaveNewArtist} className="artistSave">Save</button>
        </div>
      )}

    {artists === null ? (
      'Loading...'
    ) : (
      artists.map((artist) => (
        <Link
          to='/'
          key={artist.artistId}
          onClick={() => handleArtistSelection(artist)}
          className={`artist cursor-pointer ${selectedArtist === artist ? 'selectedArtist' : ''}`}
        >
          <div className="artistContent">
            <div className="artistImage bg-gradient-to-r from-red-400 via-gray-300 to-blue-500">&#xa0;</div>
            <div className="artistNameAndLevel">
              <div className="artistName">{artist.artistname}</div>
              <div className="artistLevel">MUSIC GOD</div>
            </div>
          </div>
        </Link>
      ))
    )}
    </div>
  </div>
  );
}

export default Artists;
