//import logo from './logo.svg';
import './App.css';
import './fonts/ClashDisplay/clash-display.css';
import './fonts/GeneralSans/general-sans.css';

import React, {useState, useEffect} from 'react';
import { Routes, Route, Outlet, Link, BrowserRouter} from "react-router-dom";



import Home from './Home';
import Header from './Header';
import Footer from './Footer';
import Release from './Release';
import SocialContent from './SocialContent';
import ReleasePlan from './ReleasePlan';

import Bio from './Bio';
import Lyrics from './Lyrics';

import Assets from './Assets';




function App() {

  const [artists, setArtists] = useState(null); // Initialize user as null
  const [selectedArtist, setSelectedArtist] = useState(null);
  const [artistReleases, setArtistReleases] = useState([]);
  const [apiCost, setApiCost] = useState(null); // Initialize user as null



  useEffect(() => {
    getArtists();
    getApiCost();

    // Set up an interval to call the function every 30 seconds (30000 milliseconds)
    const intervalId = setInterval(() => {
      getApiCost();
    }, 30000);

    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  function getApiCost() {
    fetch('/api/apicost')
      .then((response) => response.json()) // Parse response as JSON
      .then((data) => {
        setApiCost(data); // Access the user property
      })
      .catch((error) => {
        console.error(error);
        setApiCost('Error fetching user data');
      });
  }

  function handleArtistSelection(artist) {



    setSelectedArtist(artist);

    // Fetch releases by the selected artist
    fetch(`/api/releases/${artist.artist_userid}/${artist.artistid}`)
      .then((response) => response.json())
      .then((data) => {

        setArtistReleases(data); // Update the artistReleases state with the fetched data
      })
      .catch((error) => {
        console.error('Error fetching releases:', error);
        setArtistReleases([]); // Clear the artistReleases state in case of an error
      });
  }

  function getArtists() {
    fetch('/api/artists/2')
      .then((response) => response.json()) // Parse response as JSON
      .then((data) => {
        setArtists(data); // Access the artists property
      })
      .catch((error) => {
        console.error(error);
        setArtists('Error fetching user data');
      });
  }
  /*return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p class="">
          Music Agent
        </p>
      </header>
    </div>
  );*/
  return (

      <>

      {/*
      <div class="flex flex-wrap">
        <div class="w-full md:w-1/2 lg:w-1/3 p-4">
          yksi
        </div>
        <div class="w-full md:w-1/2 lg:w-1/3 p-4">
          kaksi
        </div>
        <div class="w-full md:w-1/2 lg:w-1/3 p-4">
          kolme
        </div>
      </div>
      */}
      {/* Routes nest inside one another. Nested route paths build upon
            parent route paths, and nested route elements render inside
            parent route elements. See the note about <Outlet> below. */}
      <BrowserRouter>
      <Header apiCost={apiCost} setApiCost={setApiCost}/>

      <div id="mainarea">

      <Routes>
          <Route path="/" element={<Home
            artists={artists}
            selectedArtist={selectedArtist}
            setSelectedArtist={setSelectedArtist}
            artistReleases={artistReleases}
            setArtistReleases={setArtistReleases}
            handleArtistSelection={handleArtistSelection}
            getArtists={getArtists}/>}
          />
          <Route path="/Release/:releaseId" element={<Release
            artists={artists}
            selectedArtist={selectedArtist}
            setSelectedArtist={setSelectedArtist}
            artistReleases={artistReleases}
            setArtistReleases={setArtistReleases}
            handleArtistSelection={handleArtistSelection}
            apiCost={apiCost}
            setApiCost={setApiCost}/>}
          />
          <Route path="/Bio/" element={<Bio
            artists={artists}
            selectedArtist={selectedArtist}
            setSelectedArtist={setSelectedArtist}
            handleArtistSelection={handleArtistSelection}/>}
          />
          <Route path="/Assets/" element={<Assets
            artists={artists}
            selectedArtist={selectedArtist}
            setSelectedArtist={setSelectedArtist}
            handleArtistSelection={handleArtistSelection}/>}
          />
          <Route path="/Lyrics/:releaseId" element={<Lyrics
            artists={artists}
            selectedArtist={selectedArtist}
            setSelectedArtist={setSelectedArtist}
            artistReleases={artistReleases}
            setArtistReleases={setArtistReleases}
            handleArtistSelection={handleArtistSelection}/>}
          />
          <Route path="/SocialContent/:releaseId" element={<SocialContent
            artists={artists}
            selectedArtist={selectedArtist}
            setSelectedArtist={setSelectedArtist}
            artistReleases={artistReleases}
            setArtistReleases={setArtistReleases}
            handleArtistSelection={handleArtistSelection}/>}
          />
          <Route path="/ReleasePlan/:releaseId" element={<ReleasePlan
            artists={artists}
            selectedArtist={selectedArtist}
            setSelectedArtist={setSelectedArtist}
            artistReleases={artistReleases}
            setArtistReleases={setArtistReleases}
            handleArtistSelection={handleArtistSelection}/>}
          />



          {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
      </Routes>

      </div>
      </BrowserRouter>


      </>
  );
}

export default App;
