import React, { useState } from 'react';

function Sliders({sliderValue1, setSliderValue1, handleSliderChange}) {
  // Slider state

  const [sliderValue2, setSliderValue2] = useState(1); // default to start

  return (
    <div className="sliders-container">
      <div className="slider-wrapper">
        <div className="slider-inner-wrapper">
          <label htmlFor="slider1">Creative:</label>
          <input
              type="range"
              id="slider1"
              name="slider1"
              min="0"
              max="100"
              step="1"
              value={sliderValue1}
              onChange={(e) => setSliderValue1(e.target.value)} // Just update the state on change
              onMouseUp={(e) => handleSliderChange(e.target.value)} // Handle save on mouse up
            />

          <div className="slidervalue">{sliderValue1}%</div>
        </div>
      </div>

    </div>
  );
}

export default Sliders;
