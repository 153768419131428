import React, {useState, useEffect} from 'react';
import { NavLink } from 'react-router-dom'; // Import the Link component




function Header(props) {

  const apiCost = props.apiCost;
  const setApiCost = props.setApiCost;

  const [user, setUser] = useState(null); // Initialize user as null

  useEffect(() => {
    getUser();
  }, []);

  function getUser() {
    fetch('/api/user/2')
      .then((response) => response.json()) // Parse response as JSON
      .then((data) => {
        setUser(data); // Access the user property
      })
      .catch((error) => {
        console.error(error);
        setUser('Error fetching user data');
      });
  }





  return (
    <nav id="mainnav">
      <div className="flex">
        <div className="nav-end w-1/6 navlogo"><img src="/images/mmlogo.png" width="25"/></div>
        <div className="flex justify-center w-4/6">
          <ul className="flex space-x-8">
            <li><NavLink className="hover:text-black" to="/">Dashboard</NavLink></li>
            <li><NavLink className="hover:text-black" to="/Preferences">Preferences</NavLink></li>
            <li><NavLink className="hover:text-black" to="/Something">Something</NavLink></li>
          </ul>
        </div>
        <div className="nav-end w-1/6 flex justify-end"><div id="user">{user === null ? 'Loading...' : user.userusername[0].toUpperCase()}</div><div className="apicost">${apiCost === null ? 'Loading...' : apiCost.api_cost}</div></div>
      </div>
    </nav>
  );
}

export default Header;
