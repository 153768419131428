import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import axios from 'axios';

import Artists from './Artists';
import SearchResultList from './SearchResultList';

function Assets(props) {
  const artists = props.artists;
  const selectedArtist = props.selectedArtist;
  const handleArtistSelection = props.handleArtistSelection;

  // State to store the fetched links
  const [links, setLinks] = useState([]);

  // Function to fetch links when selectedArtist changes
  const fetchLinks = async () => {
    if (selectedArtist) {
      try {
        // Replace 'YOUR_API_BASE_URL' with the actual base URL of your API
        const response = await axios.get(`/api/listLinks/${selectedArtist.artistid}`);
        setLinks(response.data); // Assuming the API response is an array of link objects
      } catch (error) {
        console.error('Error fetching links:', error);
      }
    }
  };

  // Use useEffect to fetch links when selectedArtist changes
  useEffect(() => {
    fetchLinks();
  }, [selectedArtist]);

  return (
    <div className="flex h-screen">
      <Artists
        artists={artists}
        selectedArtist={selectedArtist}
        handleArtistSelection={handleArtistSelection}
      />

      <div className="flex-1 min-h-full w-4/5 homemain">
        {selectedArtist && (
          <nav className="homenav">
            <div className="mx-auto">
              <div className="flex items-center justify-between">
                <ul className="flex space-x-2">
                  <li><Link className="hover:text-black" to="/">Releases</Link></li>
                  <li><Link className="hover:text-black" to="/Bio">Bio</Link></li>
                  <li><Link className="hover:text-black active" to="/Assets">Assets</Link></li>
                </ul>
              </div>
            </div>
          </nav>
        )}
        <div className="p-4 flex overflow-auto links" id="playarea">
          <ul>
            {links.map((link, index) => (
              <li key={index}>
                <a href={link.linkurl} target="_blank" rel="noopener noreferrer">
                  {link.linktitle}
                </a>
              </li>
            ))}
          </ul>
        </div> {/* #playarea */}
      </div>
    </div>
  );
}

export default Assets;
