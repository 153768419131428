import { NavLink, Link } from 'react-router-dom';
import React, {useState} from 'react';

import PopupUploadMusic from './PopupUploadMusic';
import Artists from './Artists';

const albumCoverImages = "/images"

function Home(props) {

  const artists = props.artists;
  const selectedArtist = props.selectedArtist;
  const handleArtistSelection = props.handleArtistSelection;
  const artistReleases = props.artistReleases;
  const setArtistReleases = props.setArtistReleases;
  const getArtists = props.getArtists;



  const [releases, setReleases] = useState(null); // Initialize user as null
  const [isPopupUploadMusicOpen, setIsPopupUploadMusicOpen] = useState(false);

  const openPopupUploadMusic = () => {
    setIsPopupUploadMusicOpen(true);
  };

  const closePopupUploadMusic = () => {
    setIsPopupUploadMusicOpen(false);
    handleArtistSelection(selectedArtist);
  };

  const closePopupUploadMusicAfterDelay = () => {
    setTimeout(() => {
      closePopupUploadMusic()
    }, 5000);
    handleArtistSelection(selectedArtist);
  };









  return (
    <div className="flex h-screen">
      <Artists
      artists={artists}
      selectedArtist={selectedArtist}
      handleArtistSelection={handleArtistSelection}
      getArtists={getArtists}/>

      <div className="flex-1 min-h-full w-4/5 homemain">
        {selectedArtist && (
        <nav className="homenav">
          <div className="mx-auto">
            <div className="flex items-center justify-between">
              <ul className="flex space-x-2">
                <li><Link className="hover:text-black active" to="/">Releases</Link></li>
                <li><Link className="hover:text-black" to="/Bio">Bio</Link></li>
                <li><Link className="hover:text-black" to="/Assets">Assets</Link></li>
              </ul>
            </div>
          </div>
        </nav>
        )}
        <div className="p-4 flex" id="playarea">
          <div id="content" className="mt-2">
            {selectedArtist ? (
              <div>
                {!Array.isArray(artistReleases) || artistReleases.length === 0 ? (
                  <>
                  <button onClick={openPopupUploadMusic} className="font-bold text-white rounded-full" id="createrelease">Add my first song</button>
                  </>
                ) : (
                  <>
                    <Link key="new-0" onClick={openPopupUploadMusic} className="release release-new  text-6xl ">+</Link>
                    {artistReleases.map((release) => (
                        <div className="release">
                        <Link className="releaseCover bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500" key={release.releaseid} to={`/Release/${release.releaseid}`} style={{
                          backgroundImage: release.releasealbumcover
                            ? `url(${albumCoverImages}/2/${release.releasealbumcover})`
                            : 'none',
                          backgroundSize: 'cover', // Set background size to 'cover'
                        }}>&#xa0;</Link>
                        <div className="releaseName">{release.releasename}</div>
                        </div>

                    ))}
                    <br clear="all"/>

                  </>
                )}
                <PopupUploadMusic isOpen={isPopupUploadMusicOpen} onClose={closePopupUploadMusic} onCloseDelay={closePopupUploadMusicAfterDelay} artist={selectedArtist}/>
              </div>
            ) : (
              <div>&#xa0;</div>
            )}
          </div> {/* #content */}
        </div> {/* #playarea */}
      </div>
    </div>
  );
}

export default Home;
